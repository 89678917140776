import { graphql } from 'gatsby'
import get from 'lodash.get'
import React from 'react'
import styled from 'styled-components'
import HTMLBlock from '../components/html-content'
import Layout from '../components/layout'
import Responsive from '../components/responsive'
import Seo from '../components/seo'
import Title from '../components/titles'
const MentionsLegales = ({ data }) => {
    const { title, content } = get(data, 'prismicLegal.data')
    return (
        <>
            <Seo title={title.text} />
            <Layout withMiniHeader>
                <Section>
                    <Responsive>
                        <Title as="h1" size="large" withDot>
                            {title.text}
                        </Title>
                        <HTMLBlock>{content.html}</HTMLBlock>
                    </Responsive>
                </Section>
            </Layout>
        </>
    )
}

export const query = graphql`
    {
        prismicLegal {
            data {
                title {
                    text
                    html
                }
                content {
                    html
                    text
                }
            }
        }
    }
`

const Section = styled.section`
    min-height: 75vh;
    padding: 4rem 0;
`

export default MentionsLegales
