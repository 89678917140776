import React from 'react'
import styled from 'styled-components'
import { titleStyleLarge, titleStyleMedium, titleStyleSmall, titleStyleTiny } from './titles'

const HTMLBlock = ({ children, ...rest }) => (
    <WrapContentDiv {...rest} dangerouslySetInnerHTML={{ __html: children }}></WrapContentDiv>
)

const withHtmlContent = (WrappedComponent) => ({ children, ...otherProps }) => (
    <WrappedComponent dangerouslySetInnerHTML={{ __html: children }} {...otherProps}></WrappedComponent>
)

const WrapContentDiv = styled.div`
    word-break: break-word;
    h1 {
        ${titleStyleLarge}
    }
    h2 {
        ${titleStyleMedium}
    }
    h3,
    h4 {
        ${titleStyleSmall}
    }
    h5,
    h6 {
        ${titleStyleTiny}
    }
`

export default HTMLBlock
export { HTMLBlock, withHtmlContent }
